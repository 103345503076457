import React from 'react';
import { graphql } from 'gatsby';
import { PageWrapper } from '../components/PageWrapper';
import { HeroSimple } from '../components/HeroSimple';
import { StructuredText } from 'react-datocms';

const PrivacyTemplate = ({
    data: {
        datoCmsPrivacypage: {
            seo,
            statePrint, stateDate,
            privacyContent,
            hero: [{
                heroTitle,
            }],
        },
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
    >
    <div className='TermsNavBackground'></div>
    <div className='TermsWrapper'>
        <h1>{heroTitle}</h1>
        <a className='TermsState'>{statePrint} {stateDate}</a>
    <StructuredText data={privacyContent}/>
    </div>
    </PageWrapper>
);

export default PrivacyTemplate;

export const query = graphql`
    query PrivacyQuery($locale: String!) {
        datoCmsPrivacypage(locale: { eq: $locale }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            hero {
                heroTitle
            }
            statePrint
            stateDate (formatString: "DD.MM.YYYY")
            privacyContent {
                value
            }
        }
    }
`;